// Transform
// options to pass to the utility API
//

// Transform builder
.transform {
    --#{$variable-prefix}translate-x: 0;
    --#{$variable-prefix}translate-y: 0;
    --#{$variable-prefix}translate-z: 0;
    --#{$variable-prefix}perspective: 0;
    --#{$variable-prefix}rotate: 0;
    --#{$variable-prefix}rotate-x: 0;
    --#{$variable-prefix}rotate-y: 0;
    --#{$variable-prefix}skew-x: 0;
    --#{$variable-prefix}skew-y: 0;
    --#{$variable-prefix}scale-x: 1;
    --#{$variable-prefix}scale-y: 1;
    transform: translateX(var(--#{$variable-prefix}translate-x)) translateY(var(--#{$variable-prefix}translate-y)) perspective(var(--#{$variable-prefix}perspective)) rotate(var(--#{$variable-prefix}rotate)) rotateX(var(--#{$variable-prefix}rotate-x)) rotateY(var(--#{$variable-prefix}rotate-y)) skewX(var(--#{$variable-prefix}skew-x)) skewY(var(--#{$variable-prefix}skew-y)) scaleX(var(--#{$variable-prefix}scale-x)) scaleY(var(--#{$variable-prefix}scale-y));
}

// Default values
$transform-values: map-get($config, transform) !default;
$transform-state: null !default;
$transform-responsive: true !default;

// Override default options with the theme's ones
$transform-defaults: (
    responsive: if(map-has-key($theme, transform, responsive), map-get($theme, transform, responsive), $transform-responsive),
    values: if(map-has-key($theme, transform, values), map-get($theme, transform, values), $transform-values),
    disable: if(map-has-key($theme, transform, disable), map-get($theme, transform, disable), false)
) !default;

$transform-options: (
    property: transform,
    class: transform,
    responsive: map-get($transform-defaults, responsive),
    values: map-get($transform-defaults, values),
);

// Merge states (variants)
@if(map-has-key($theme, transform, state)) {
    // Push new values to default list if not null
    $transform-state: if($transform-state != null, append($transform-state, map-get($theme, transform, state)), map-get($theme, transform, state));

    // Merge states with the utility options
    $transform-options: map-merge($transform-options, (
        state: map-get($theme, transform, state)
    ));
} @else {
    @if($transform-state != null) {
        $transform-options: map-merge($transform-options, (
            state: $transform-state
        ));
    }
}
