// Blur
// options to pass to the utility API
//

.filter {
    --#{$variable-prefix}blur: 0;
    --#{$variable-prefix}contrast: 0;
    --#{$variable-prefix}grayscale: 0;
    --#{$variable-prefix}hue-rotate: 0;
    --#{$variable-prefix}drop-shadow: 0;
    filter: blur(var(--#{$variable-prefix}blur));
}

// Default values
$blur-values: map-get($config, blur) !default;
$blur-state: null !default;
$blur-responsive: true !default;

// Override default options with the theme's ones
$blur-defaults: (
    responsive: if(map-has-key($theme, blur, responsive), map-get($theme, blur, responsive), $blur-responsive),
    values: if(map-has-key($theme, blur, values), map-get($theme, blur, values), $blur-values),
    disable: if(map-has-key($theme, blur, disable), map-get($theme, blur, disable), false)
) !default;

$blur-options: (
    property: --#{$variable-prefix}blur,
    class: blur,
    responsive: map-get($blur-defaults, responsive),
    values: map-get($blur-defaults, values),
);

// Merge states (variants)
@if(map-has-key($theme, blur, state)) {
    // Push new values to default list if not null
    $blur-state: if($blur-state != null, append($blur-state, map-get($theme, blur, state)), map-get($theme, blur, state));

    // Merge states with the utility options
    $blur-options: map-merge($blur-options, (
        state: map-get($theme, blur, state)
    ));
} @else {
    @if($blur-state != null) {
        $blur-options: map-merge($blur-options, (
            state: $blur-state
        ));
    }
}
