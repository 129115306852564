//
// Switch
//

.form-switch {
    min-height: $form-switch-min-height;
}

.form-switch > .form-check-input {
    height: $form-switch-min-height;
    margin-top: 0; // Form switch input height is always equal to the height of the switch
}

.form-switch > .form-check-label {
    margin-top: calc((#{$form-switch-min-height} - #{$line-height-base} * 1em) / 2);
}
