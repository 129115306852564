// Color system

// White/Grays/Black colors
$white:    #FFF !default;
$gray-50:  #FAFAFA !default;    // Alternate background
$gray-100: #F5F9FC !default;    // Base background
$gray-200: #E7EAF0 !default;
$gray-300: #CFD6DF !default;
$gray-400: #ABB6C5 !default;
$gray-500: #8898A9 !default;    // Disabled text
$gray-600: #6B7B93 !default;    // Placeholder text
$gray-700: #525F7F !default;    // Text content
$gray-800: #2D3748 !default;    // Text content old(44444e)
$gray-900: #16192C !default;    // Text content
$black:    #000 !default;


// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio:   2 !default;

// Customize the light and dark text colors for use in our color contrast function.
$color-contrast-dark:      $black !default;
$color-contrast-light:     $white !default;


// fusv-disable
$grays: (
    "50":  $gray-50,
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900
) !default;
// fusv-enable


// Base colors
$blue:    #0099FF !default;
$indigo:  #5C60F5 !default;
$purple:  #8957FF !default;
$pink:    #FF579A !default;
$magenta: #EE33FF !default;
$red:     #FF3366 !default;
$orange:  #FF8C00 !default;
$yellow:  #FFBB00 !default;
$green:   #00CC88 !default;
$teal:    #22DDDD !default;
$cyan:    #00D4FF !default;

// fusv-disable
$blue-100: tint-color($blue, 80%) !default;
$blue-200: tint-color($blue, 60%) !default;
$blue-300: tint-color($blue, 40%) !default;
$blue-400: tint-color($blue, 20%) !default;
$blue-500: $blue !default;
$blue-600: shade-color($blue, 20%) !default;
$blue-700: shade-color($blue, 40%) !default;
$blue-800: shade-color($blue, 60%) !default;
$blue-900: shade-color($blue, 80%) !default;

$indigo-100: tint-color($indigo, 80%) !default;
$indigo-200: tint-color($indigo, 60%) !default;
$indigo-300: tint-color($indigo, 40%) !default;
$indigo-400: tint-color($indigo, 20%) !default;
$indigo-500: $indigo !default;
$indigo-600: shade-color($indigo, 20%) !default;
$indigo-700: shade-color($indigo, 40%) !default;
$indigo-800: shade-color($indigo, 60%) !default;
$indigo-900: shade-color($indigo, 80%) !default;

$purple-100: tint-color($purple, 80%) !default;
$purple-200: tint-color($purple, 60%) !default;
$purple-300: tint-color($purple, 40%) !default;
$purple-400: tint-color($purple, 20%) !default;
$purple-500: $purple !default;
$purple-600: shade-color($purple, 20%) !default;
$purple-700: shade-color($purple, 40%) !default;
$purple-800: shade-color($purple, 60%) !default;
$purple-900: shade-color($purple, 80%) !default;

$pink-100: tint-color($pink, 80%) !default;
$pink-200: tint-color($pink, 60%) !default;
$pink-300: tint-color($pink, 40%) !default;
$pink-400: tint-color($pink, 20%) !default;
$pink-500: $pink !default;
$pink-600: shade-color($pink, 20%) !default;
$pink-700: shade-color($pink, 40%) !default;
$pink-800: shade-color($pink, 60%) !default;
$pink-900: shade-color($pink, 80%) !default;

$magenta-100: tint-color($magenta, 80%) !default;
$magenta-200: tint-color($magenta, 60%) !default;
$magenta-300: tint-color($magenta, 40%) !default;
$magenta-400: tint-color($magenta, 20%) !default;
$magenta-500: $magenta !default;
$magenta-600: shade-color($magenta, 20%) !default;
$magenta-700: shade-color($magenta, 40%) !default;
$magenta-800: shade-color($magenta, 60%) !default;
$magenta-900: shade-color($magenta, 80%) !default;

$red-100: tint-color($red, 80%) !default;
$red-200: tint-color($red, 60%) !default;
$red-300: tint-color($red, 40%) !default;
$red-400: tint-color($red, 20%) !default;
$red-500: $red !default;
$red-600: shade-color($red, 20%) !default;
$red-700: shade-color($red, 40%) !default;
$red-800: shade-color($red, 60%) !default;
$red-900: shade-color($red, 80%) !default;

$orange-100: tint-color($orange, 80%) !default;
$orange-200: tint-color($orange, 60%) !default;
$orange-300: tint-color($orange, 40%) !default;
$orange-400: tint-color($orange, 20%) !default;
$orange-500: $orange !default;
$orange-600: shade-color($orange, 20%) !default;
$orange-700: shade-color($orange, 40%) !default;
$orange-800: shade-color($orange, 60%) !default;
$orange-900: shade-color($orange, 80%) !default;

$yellow-100: tint-color($yellow, 80%) !default;
$yellow-200: tint-color($yellow, 60%) !default;
$yellow-300: tint-color($yellow, 40%) !default;
$yellow-400: tint-color($yellow, 20%) !default;
$yellow-500: $yellow !default;
$yellow-600: shade-color($yellow, 20%) !default;
$yellow-700: shade-color($yellow, 40%) !default;
$yellow-800: shade-color($yellow, 60%) !default;
$yellow-900: shade-color($yellow, 80%) !default;

$green-100: tint-color($green, 80%) !default;
$green-200: tint-color($green, 60%) !default;
$green-300: tint-color($green, 40%) !default;
$green-400: tint-color($green, 20%) !default;
$green-500: $green !default;
$green-600: shade-color($green, 20%) !default;
$green-700: shade-color($green, 40%) !default;
$green-800: shade-color($green, 60%) !default;
$green-900: shade-color($green, 80%) !default;

$teal-100: tint-color($teal, 80%) !default;
$teal-200: tint-color($teal, 60%) !default;
$teal-300: tint-color($teal, 40%) !default;
$teal-400: tint-color($teal, 20%) !default;
$teal-500: $teal !default;
$teal-600: shade-color($teal, 20%) !default;
$teal-700: shade-color($teal, 40%) !default;
$teal-800: shade-color($teal, 60%) !default;
$teal-900: shade-color($teal, 80%) !default;

$cyan-100: tint-color($cyan, 80%) !default;
$cyan-200: tint-color($cyan, 60%) !default;
$cyan-300: tint-color($cyan, 40%) !default;
$cyan-400: tint-color($cyan, 20%) !default;
$cyan-500: $cyan !default;
$cyan-600: shade-color($cyan, 20%) !default;
$cyan-700: shade-color($cyan, 40%) !default;
$cyan-800: shade-color($cyan, 60%) !default;
$cyan-900: shade-color($cyan, 80%) !default;
// fusv-enable

// scss-docs-start colors-map
$colors: (
    "gray-50": $gray-50,
    "gray-100": $gray-100,
    "gray-200": $gray-200,
    "gray-300": $gray-300,
    "gray-400": $gray-400,
    "gray-500": $gray-500,
    "gray-600": $gray-600,
    "gray-700": $gray-700,
    "gray-800": $gray-800,
    "gray-900": $gray-900,

    "blue-100": $blue-100,
    "blue-200": $blue-200,
    "blue-300": $blue-300,
    "blue-400": $blue-400,
    "blue-500": $blue-500,
    "blue-600": $blue-600,
    "blue-700": $blue-700,
    "blue-800": $blue-800,
    "blue-900": $blue-900,

    "indigo-100": $indigo-100,
    "indigo-200": $indigo-200,
    "indigo-300": $indigo-300,
    "indigo-400": $indigo-400,
    "indigo-500": $indigo-500,
    "indigo-600": $indigo-600,
    "indigo-700": $indigo-700,
    "indigo-800": $indigo-800,
    "indigo-900": $indigo-900,

    "purple-100": $purple-100,
    "purple-200": $purple-200,
    "purple-300": $purple-300,
    "purple-400": $purple-400,
    "purple-500": $purple-500,
    "purple-600": $purple-600,
    "purple-700": $purple-700,
    "purple-800": $purple-800,
    "purple-900": $purple-900,

    "pink-100": $pink-100,
    "pink-200": $pink-200,
    "pink-300": $pink-300,
    "pink-400": $pink-400,
    "pink-500": $pink-500,
    "pink-600": $pink-600,
    "pink-700": $pink-700,
    "pink-800": $pink-800,
    "pink-900": $pink-900,

    "magenta-100": $magenta-100,
    "magenta-200": $magenta-200,
    "magenta-300": $magenta-300,
    "magenta-400": $magenta-400,
    "magenta-500": $magenta-500,
    "magenta-600": $magenta-600,
    "magenta-700": $magenta-700,
    "magenta-800": $magenta-800,
    "magenta-900": $magenta-900,

    "red-100": $red-100,
    "red-200": $red-200,
    "red-300": $red-300,
    "red-400": $red-400,
    "red-500": $red-500,
    "red-600": $red-600,
    "red-700": $red-700,
    "red-800": $red-800,
    "red-900": $red-900,

    "orange-100": $orange-100,
    "orange-200": $orange-200,
    "orange-300": $orange-300,
    "orange-400": $orange-400,
    "orange-500": $orange-500,
    "orange-600": $orange-600,
    "orange-700": $orange-700,
    "orange-800": $orange-800,
    "orange-900": $orange-900,

    "yellow-100": $yellow-100,
    "yellow-200": $yellow-200,
    "yellow-300": $yellow-300,
    "yellow-400": $yellow-400,
    "yellow-500": $yellow-500,
    "yellow-600": $yellow-600,
    "yellow-700": $yellow-700,
    "yellow-800": $yellow-800,
    "yellow-900": $yellow-900,

    "green-100": $green-100,
    "green-200": $green-200,
    "green-300": $green-300,
    "green-400": $green-400,
    "green-500": $green-500,
    "green-600": $green-600,
    "green-700": $green-700,
    "green-800": $green-800,
    "green-900": $green-900,

    "teal-100": $teal-100,
    "teal-200": $teal-200,
    "teal-300": $teal-300,
    "teal-400": $teal-400,
    "teal-500": $teal-500,
    "teal-600": $teal-600,
    "teal-700": $teal-700,
    "teal-800": $teal-800,
    "teal-900": $teal-900,

    "cyan-100": $cyan-100,
    "cyan-200": $cyan-200,
    "cyan-300": $cyan-300,
    "cyan-400": $cyan-400,
    "cyan-500": $cyan-500,
    "cyan-600": $cyan-600,
    "cyan-700": $cyan-700,
    "cyan-800": $cyan-800,
    "cyan-900": $cyan-900,
) !default;
// scss-docs-end colors-map

// Theme colors
$primary:       $indigo !default;
$secondary:     $gray-300 !default;
$tertiary:      $pink !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $orange !default;
$danger:        $red !default;
$light:         $gray-200 !default;
$dark:          $gray-900 !default;

// scss-docs-start theme-colors-map
$theme-colors: (
    "primary":    $primary,
    "secondary":  $secondary,
    "tertiary":   $tertiary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "white":      $white,
    "light":      $light,
    "dark":       $dark
) !default;
// scss-docs-end theme-colors-map

$bootstrap:     #7952b3 !default;
$laravel:       #ff2d20 !default;
$react:         #61dafb !default;
$facebook:      #3b5999 !default;
$twitter:       #1da1f2 !default;
$instagram:     #e4405f !default;
$pinterest:     #bd081c !default;
$youtube:       #cd201f !default;
$dribbble:      #ea4c89 !default;
$github:        #222222 !default;
$reddit:        #FF4301 !default;

// scss-docs-start theme-colors-map
$brand-colors: (
    "bootstrap":    $bootstrap,
    "laravel":      $laravel,
    "react":        $react,
    "facebook":     $facebook,
    "twitter":      $twitter,
    "instagram":    $instagram,
    "pinterest":    $pinterest,
    "youtube":      $youtube,
    "dribbble":     $dribbble,
    "github":       $github,
    "reddit":       $reddit
) !default;
// scss-docs-end brand-colors-map


// Gradient
//
// The gradient which is added to components if `$enable-gradients` is `true`
// This gradient is also added to elements with `.bg-gradient`

$gradient: linear-gradient(180deg, rgba($white, .15), rgba($white, 0)) !default;

// $gradient: linear-gradient(50deg, rgba($white, .15) 0, rgba($white, 0) 100%) !default;

// Bg Soft level

$bg-soft-level:           -80% !default;
$bg-shade-level:           15% !default;
$bg-tint-level:            15% !default;


// Body
//
// Settings for the `<body>` element.

$body-bg:                   $white !default;
$body-color:                $gray-700 !default;
$body-text-align:           null !default;


// Surfaces
//
// Colors used for content area background colors

$surface-colors: () !default;
$surface-colors: map-merge((
    "primary":      $body-bg,
    "secondary":    $gray-100,
    "tertiary":     $gray-50,
    "light":        $gray-200,
    "dark":         shade-color($dark, 7%)
), $surface-colors);
