:root {
    // Custom variable values only support SassScript inside `#{}`.
    @each $color, $value in $colors {
        --#{$variable-prefix}#{$color}: #{$value};
    }

    @each $color, $value in $theme-colors {
        --#{$variable-prefix}#{$color}: #{$value};
        --#{$variable-prefix}shade-#{$color}: #{shade-color($value, 10%)};
        --#{$variable-prefix}tint-#{$color}: #{tint-color($value, 10%)};
    }

    @each $color, $value in $surface-colors {
        --#{$variable-prefix}surface-#{$color}: #{$value};
    }

    // Use `inspect` for lists so that quoted items keep the quotes.
    // See https://github.com/sass/sass/issues/2383#issuecomment-336349172

    --#{$variable-prefix}font-sans-serif: #{inspect($font-sans-serif-bundle)};
    --#{$variable-prefix}font-monospace: #{inspect($font-monospace-bundle)};
    --#{$variable-prefix}font-display: #{inspect($font-display-bundle)};
    --#{$variable-prefix}font-serif: #{inspect($font-serif-bundle)};
    --#{$variable-prefix}gradient: #{$gradient};

    tab-size: $tab-size;
}
